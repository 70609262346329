import { ApplicationConfigType } from './applicationConfigType';

export const ApplicationConfig: ApplicationConfigType = {
    apiURL: 'https://beta.apiv2.garam.26area.com',
    chatbotURL: 'https://beta.chatbot.garam.26area.com',
    streamChatAPIKey: 'q53kmqhm8k7g',
    AWSConfigs: {
        Auth: {
            "aws_project_region": "us-west-2",
            // @ts-ignore
            "aws_cognito_region": "us-west-2",
            "aws_user_pools_id": "us-west-2_IkMedaTOs",
            "aws_user_pools_web_client_id": "29ggmvr0482brd7h6rniupp8m5",
            "oauth": {
                "domain": "https://area26userpool.auth.us-west-2.amazoncognito.com",
                "scope": [
                    "aws.cognito.signin.user.admin",
                    "email",
                    "openid",
                    "phone",
                    "profile"
                ],
                "redirectSignIn": "https://beta.dashboard.aieverywhere.26area.com/,https://dashboard.aieverywhere.26area.com/",
                "redirectSignOut": "https://beta.dashboard.aieverywhere.26area.com/,https://dashboard.aieverywhere.26area.com/",
                "responseType": "code"
            },
            "federationTarget": "COGNITO_USER_POOLS",
            "aws_cognito_username_attributes": [
                "EMAIL"
            ],
            "aws_cognito_social_providers": [],
            "aws_cognito_signup_attributes": [],
            "aws_cognito_mfa_configuration": "OFF",
            "aws_cognito_mfa_types": [],
            "aws_cognito_password_protection_settings": {
                "passwordPolicyMinLength": 8,
                "passwordPolicyCharacters": [
                    "REQUIRES_LOWERCASE",
                    "REQUIRES_UPPERCASE",
                    "REQUIRES_NUMBERS",
                    "REQUIRES_SYMBOLS"
                ]
            },
            "aws_cognito_verification_mechanisms": [
                "EMAIL"
            ]
        }
    },
    stripe: {
        publishKey: 'pk_test_51PWnBqIge8EjM6ZeBNXFFoDURwuJIj0g917jDRpi0suroztsdPjoAoJQSt2JzqFxeX81JrLWRrbQVS7hqHGnKvg900jru84bA3',
    },
}